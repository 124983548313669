<form #form="ngForm" (submit)="onSubmit()">
  <div nxLayout="grid maxwidth" class="contact-data-input-remove-not-available contact-data-input-remove-info nx-margin-top-4m">

    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">
        <dsmkd-headers title="Digitales Kundenprofil"
                       mainTitle="Kontaktdaten"
        ></dsmkd-headers>
      </div>
    </div>

    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">

        <dsmkd-contact-data-input
          [fieldType]=emailFieldType
          #emailField
          (statusChanged)="optOutStatusChanged()"
          [contactDataItems]="emails"
        ></dsmkd-contact-data-input>

        <nx-message *ngIf="showEmailAlreadyExistsError" nxContext="error" class="nx-margin-top-0 nx-margin-bottom-m">
          Die angegebene E-Mail-Adresse wird bereits für das Meine Allianz Konto einer anderen Person verwendet.
          Bitte geben Sie eine andere E-Mail-Adresse an.
        </nx-message>

        <dsmkd-contact-data-input
          [fieldType]=mobileNumberFieldType
          #mobileNumberField
          (statusChanged)="optOutStatusChanged()"
          [contactDataItems]="mobileNumbers"
        ></dsmkd-contact-data-input>
      </div>
    </div>

    <div nxRow nxRowJustify="center" class="nx-margin-top-4m">
      <div nxCol="12,12,6">
        <dsmkd-komm-data-programm-overview
          #kommDataProgrammOverviewComponent
          [weweActive]="hasWewe"
          [mazActive]="hasMaz"
          [deweActive]="hasDewe"
          [personName]="kundenName"
          [legalPerson]="legalPerson"
          [authorizedRepresentative]="authorizedRepresentative"
          [hasAddress]="hasAddress"
          [isNovaUser]="isNovaSDWUser"
        >
        </dsmkd-komm-data-programm-overview>
      </div>
    </div>

    <div class="nx-margin-top-4m" nxRow nxRowJustify="center">
      <div class="button-wrapper dssbs-padding-bottom-s" nxCol="12,12,3" nxAlignSelf="center,center,end"
           nxColOrder='first,first,last,last'>
        <button class="button-right-small-up nx-margin-bottom-0" [disabled]=isSubmitDisabled
                nxButton="primary" type="submit">
          Weiter
        </button>
      </div>

      <div class="button-wrapper link-text-center-small dssbs-padding-bottom-s" nxCol="12,12,3" nxAlignSelf="center">
        <button nxPlainButton type="button" (click)="navigateBack()" class="nx-margin-bottom-0 nx-margin-left-s">
          ZURÜCK
        </button>
      </div>
    </div>

    <div nxRow nxRowJustify="center" class="nx-padding-top-s">
      <div nxCol="12,12,6">
        <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
      </div>
    </div>
  </div>
</form>
