import {Injectable} from '@angular/core';
import {AuthInfoModel} from '../models/auth-info.model';
import {KommDataPostResponse} from '../models/komm-data-post.response';
import {WeweResponse} from '../models/wewe.response';
import {ProgramParticipationType} from '../models/kommdata.model';
import {KommDataChangeSummaryModel} from '../../komm-data-change/model/komm-data-change-summary.model';
import {KommDataChangeResponseModel} from '../../komm-data-change/model/komm-data-change-response.model';

@Injectable()
export class AppStateService {

  private authInfoModel: AuthInfoModel;
  private kommDataPostResponseModel: KommDataPostResponse;
  private weweResponseModel: WeweResponse;
  private programParticipationTypesModel: ProgramParticipationType[];
  private kommDataChangeSummaryModel: KommDataChangeSummaryModel;
  private kommDataChangeResponseModel: KommDataChangeResponseModel;

  get authInfo() {
    if (!this.authInfoModel) {
      this.authInfoModel = new AuthInfoModel();
    }
    return this.authInfoModel;
  }

  get kommDataPostResponse() {
    if (!this.kommDataPostResponseModel) {
      this.kommDataPostResponseModel = new KommDataPostResponse();
    }
    return this.kommDataPostResponseModel;
  }

  get weweResponse() {
    if (!this.weweResponseModel) {
      this.weweResponseModel = new WeweResponse();
    }
    return this.weweResponseModel;
  }

  get programParticipationTypes() {
    if (!this.programParticipationTypesModel) {
      this.programParticipationTypesModel = [];
    }
    return this.programParticipationTypesModel;
  }

  get kommDataChangeSummary() {
    if (!this.kommDataChangeSummaryModel) {
      this.kommDataChangeSummaryModel = {kommdataChangeEmails: [], kommdataChangeMobileNumbers: []};
    }
    return this.kommDataChangeSummaryModel;
  }

  get kommDataChangeResponse() {
    if (!this.kommDataChangeResponseModel) {
      this.kommDataChangeResponseModel = {
        emailChangeSuccess: false,
        mobileChangeSuccess: false,
        errorType: null
      };
    }
    return this.kommDataChangeResponseModel;
  }
}
