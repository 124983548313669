import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AuthorizedRepresentative,
  DeletableType,
  KommDataItem,
  ProgramParticipationItem,
  ProgramParticipationType
} from '../../../core/models/kommdata.model';
import {ModelService} from '../../../core/services/model.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';
import {ScrollingService} from '../../../core/services/scrolling.service';
import {NxDialogService} from '@aposin/ng-aquila/modal';

@Component({
  selector: 'dsmkd-komm-data-edit',
  templateUrl: './komm-data-edit.component.html',
  styleUrls: ['./komm-data-edit.component.scss']
})
export class KommDataEditComponent implements OnInit {

  emails: KommDataItem[] = [];
  mobileNumbers: KommDataItem[] = [];
  kommDataToDelete: KommDataItem;
  kommDataToDeleteType: string;

  mazEmail: string;
  mazMobileNumber: string;
  customerName: string;

  mobileNumberFieldType = 'phone';
  emailFieldType = 'email';
  showErrorMissingKommData = false;

  kundenName: string = '';
  trackingSourceDetail = '';
  legalPerson = false;
  @ViewChild('deleteModalBody') feedbackModalTemplate: TemplateRef<any>;

  constructor(private readonly modelService: ModelService,
              private readonly router: Router,
              private readonly titleService: Title,
              private readonly serviceHubRedirectService: ServiceHubRedirectService,
              private readonly scrollingService: ScrollingService,
              private readonly dialogService: NxDialogService,
  ) {
  }

  ngOnInit(): void {
    const kommData = this.modelService.getKommData();
    const programParticipations = kommData.participations.length === 0 ? null : kommData.participations;

    if (programParticipations) {
      const mazProgramParticipationItem = programParticipations.filter(program => program.type === ProgramParticipationType.MAZ)[0];
      this.setMazEmailAndMobileNumber(mazProgramParticipationItem);
    }
    this.emails = kommData.emails.length === 0 ? [] : kommData.emails.filter(kommdataItem => kommdataItem.value !== this.mazEmail);
    this.mobileNumbers = kommData.mobileNumbers.length === 0 ? [] : kommData.mobileNumbers.filter(kommdataItem => kommdataItem.value !== this.mazMobileNumber);
    this.modelService.getHeaderUserDetails().subscribe((personName) => {
      this.customerName = personName.firstName + ' ' + personName.lastName;
    });
  }

  private setMazEmailAndMobileNumber(mazProgramParticipationItem: ProgramParticipationItem): void {
    this.mazEmail = mazProgramParticipationItem.emails ? mazProgramParticipationItem.emails[0]?.value : '';
    this.mazMobileNumber = mazProgramParticipationItem.mobileNumbers ? mazProgramParticipationItem.mobileNumbers[0]?.value : '';
  }

  hasOtherCommunicationData(): boolean {
    return this.emails.length > 0 || this.mobileNumbers.length > 0;
  }

  navigateBack(): void {
    this.serviceHubRedirectService.doRedirect();
  }

  navigateToMazChange(): void {
    this.scrollingService.scrollToTop();
    this.router.navigate(['change', '']);
  }

  navigateToAddKommData(): void {
    this.scrollingService.scrollToTop();
    this.router.navigate(['add', 'digital-profile']);
  }

  deleteKommItem(kommItem: KommDataItem, type: string): void {
    if (this.isDeleteDisabled(kommItem)) {
      return;
    }
    this.kommDataToDelete = kommItem;
    this.kommDataToDeleteType = type;

    this.dialogService.open(this.feedbackModalTemplate, {
      ariaLabel: 'Delete',
      closeIconButtonLabel: 'Dialog schließen',
      showCloseIcon: true,
      height: '150'
    });
  }

  closeDeleteDialog() {
    this.dialogService.closeAll();
  }

  sendDeleteRequest() {
    console.log(this.kommDataToDelete);
    console.log('deleted');
  }

  getTrashIcon(dataItem: KommDataItem): string {
    return !this.isDeleteDisabled(dataItem) ? 'icon-trash' : 'icon-trash-deactivated';
  }

  isDeleteDisabled(dataItem: KommDataItem): boolean {
    return DeletableType.DELETABLE !== dataItem.deletableType;
  }

  getTooltipText(deletableType: DeletableType, kommDataType: string): string {
    let tooltipText = '';
    if (deletableType === DeletableType.PRIO_FLAG) {
      tooltipText = `Die ${kommDataType} ist mit Prio-Kennzeichen hinterlegt. Die Umstellung des Priokennzeichen auf ` +
        'eine andere E-Mail-Adresse kann derzeit nur über AMIS vorgenommen werden.';
    }
    if (deletableType === DeletableType.MARKED_FOR_UPDATE) {
      tooltipText = `Die ${kommDataType} ist bereits für ein Update / Löschung markiert.`;
    }
    if (deletableType === DeletableType.LINKED_WITH_PROGRAMM) {
      tooltipText = `Die ${kommDataType} ist mit einer Programmteilnahme verknüpft.`;
    }
    return tooltipText;
  }
}
