import {Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {IFile} from '../../files-upload/models/file.model';
import {ITrackingAttribute} from '../models/tracking-attribute';
import {IError} from '../models/error.model';
import {HeaderUserDetails} from '../models/header-user-details.model';
import {KommDataModel} from '../models/kommdata.model';
import {AmslKommDataModel} from '../models/amsl-person.model';
import {AddressModel} from '../models/address.model';

type UploadFileStatus = 'added' | 'deleted';

@Injectable()
export class ModelService {

  private uploadedFiles: IFile[];
  private errorModel: IError;
  private readonly uploadedFilesSubject: Subject<UploadFileStatus>;
  private uploadTrackingSubject: Subject<ITrackingAttribute>;
  private kommData: KommDataModel;
  private address: AddressModel;
  private amslKommData: AmslKommDataModel;
  private readonly headerUserDetails?: Subject<HeaderUserDetails>;

  constructor() {
    this.uploadedFilesSubject = new Subject<UploadFileStatus>();
    this.uploadTrackingSubject = new Subject<ITrackingAttribute>();
    this.headerUserDetails = new Subject<HeaderUserDetails>();
    this.resetModelService();
  }

  addUploadedFiles(files: IFile[]) {
    files.forEach(value => this.uploadedFiles.push(value));
    this.changeStatus('added');
  }

  getUploadedFiles(): IFile[] {
    return this.uploadedFiles;
  }

  getUploadedFilesIds(): string[] {
    return Array.from(new Set(this.uploadedFiles.map(file => file.fileId)).values());
  }

  getErrorModel(): IError {
    return this.errorModel;
  }

  resetModelService() {
    this.uploadedFiles = [] as IFile[];
    this.errorModel = {} as IError;
  }

  deleteUploadedFile(file: IFile) {
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      if (this.uploadedFiles[i] === file) {
        this.uploadedFiles.splice(i, 1);
        this.changeStatus('deleted');
        i--;
      }
    }
  }

  deleteAllUploadedFiles() {
    this.uploadedFiles = [];
  }

  addUploadTrackingData(trackingAttribute: ITrackingAttribute) {
    this.uploadTrackingSubject.next(trackingAttribute);
  }

  getUploadTrackingData(): Observable<ITrackingAttribute> {
    return this.uploadTrackingSubject.asObservable();
  }

  resetUploadedTrackingData(): void {
    this.uploadTrackingSubject = new Subject<ITrackingAttribute>();
  }

  private changeStatus(status: UploadFileStatus) {
    this.uploadedFilesSubject.next(status);
  }

  getStatus(): Observable<UploadFileStatus> {
    return this.uploadedFilesSubject.asObservable();
  }

  setKommData(kommData: KommDataModel): void {
    this.kommData = kommData;
  }

  getKommData(): KommDataModel {
    return this.kommData;
  }

  setAmslKommData(kommData: AmslKommDataModel): void {
    this.amslKommData = kommData;
  }

  getAmslKommData(): AmslKommDataModel {
    return this.amslKommData;
  }

  setHeaderUserDetails(headerUserDetails: HeaderUserDetails) {
    this.headerUserDetails.next(headerUserDetails);
  }

  getHeaderUserDetails(): Subject<HeaderUserDetails> {
    return this.headerUserDetails;
  }

  setAddress(address: AddressModel): void {
    this.address = address;
  }

  getAddress(): AddressModel {
    return this.address;
  }
}
