<form id="form" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
  <div nxLayout="grid maxwidth">

    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6" class="nx-margin-top-xl">
        <dsmkd-headers mainTitle="{{'wewe.form.title.headline' | translate}}"
                       subtitle="{{'wewe.form.title.description' | translate}}"
        ></dsmkd-headers>
      </div>
    </div>

    <div nxRow nxRowJustify="center" class="margin-top-32">
      <div nxCol="12,10,8,8">
        <dsmkd-wewe-person-detail [person]="amslPersonModel"></dsmkd-wewe-person-detail>
        <p nxCopytext="normal" class="margin-bottom-48">{{'wewe.form.user.description.text' | translate}}</p>
      </div>
    </div>

    <div nxRow nxRowJustify="center">
      <div nxCol="12,10,8,8">
        <ol nxList="ordered-circles">

          <li>{{'wewe.form.attachment.headline' | translate}}</li>
          <p nxCopytext="normal" class="margin-bottom-8">
            {{'wewe.form.attachment.description.text' | translate}}
            <nx-link nxStyle="text"><a href="assets/downloads/uebersicht-verwendbarer-wewe-formulare.pdf" download
                                       target="_blank">{{'wewe.form.attachment.description.link' | translate}}</a>
            </nx-link>
          </p>
          <dsmkd-files-upload
            [filesFormControl]="fileIds"
            errorsPrefix="wewe.form.attachment.error"
            [fileUploadConfig]="fileUploadConfig"
            [acceptedMaxFiles]="1"
            [acceptedFileFormat]="'jpeg, jpg, pdf, png'"
            [acceptedFileSize]="3"
            [showInfoMsg]="true">
          </dsmkd-files-upload>

          <li class="margin-top-48">{{'wewe.form.communicationData.headline' | translate}}</li>
          <div id="communicationData" formGroupName="communicationData">
            <p nxCopytext="normal" class="margin-bottom-24">
              {{'wewe.form.communicationData.description.text' | translate}}
            </p>
            <div nxRow>
              <div nxCol="12">
                <nx-checkbox-group formControlName="email">
                  <nx-label [size]="'small'"> {{'wewe.form.communicationData.email.title' | translate}}</nx-label>
                  <p nxCopytext="normal" *ngIf="amslPersonModel.emailAddresses?.length < 1">
                    Keine Emailadresse vorhanden
                  </p>
                  <div class="horizontal-checkboxes">

                    <nx-checkbox class="margin-right-32"
                                 *ngFor="let email of amslPersonModel.emailAddresses" value="{{email}}">
                      {{email}}
                    </nx-checkbox>

                  </div>
                </nx-checkbox-group>
              </div>
            </div>


            <div nxRow>
              <div nxCol="6">
                <nx-checkbox-group formControlName="mobilePhone">
                  <nx-label [size]="'small'"> {{'wewe.form.communicationData.mobilephone.title' | translate}}</nx-label>
                  <p nxCopytext="normal" *ngIf="amslPersonModel.mobileNumbers?.length < 1">Keine Mobilfunknummer
                    vorhanden</p>
                  <nx-checkbox *ngFor="let mobilephone of amslPersonModel.mobileNumbers" value="{{mobilephone}}">
                    {{mobilephone | mobileNumberFormat}}
                  </nx-checkbox>
                </nx-checkbox-group>
              </div>
              <div nxCol="6">
                <nx-checkbox-group formControlName="landlinePhone">
                  <nx-label
                    [size]="'small'"> {{'wewe.form.communicationData.landlinephone.title' | translate}}</nx-label>
                  <p nxCopytext="normal" *ngIf="amslPersonModel.telNumbers?.length < 1">Keine Festnetznummer
                    vorhanden</p>
                  <nx-checkbox *ngFor="let landlinephone of amslPersonModel.telNumbers" value="{{landlinephone}}">
                    {{landlinephone | mobileNumberFormat}}
                  </nx-checkbox>
                </nx-checkbox-group>
              </div>
            </div>
            <dsmkd-error-message-container [control]="communicationData"
                                           messagePrefix="wewe.form.communicationData.error"
                                           class="margin-bottom-16"></dsmkd-error-message-container>

          </div>


          <li class="margin-top-24">{{'wewe.form.consent.headline' | translate}}</li>
          <div id="consent" formGroupName="consent">
            <p nxCopytext="normal" class="margin-bottom-24">
              {{'wewe.form.consent.description' | translate}}
            </p>
            <nx-checkbox ngDefaultControl formControlName="wewe">
              <strong>{{'wewe.form.consent.wewe.text' | translate}}</strong><br>
              {{'wewe.form.consent.wewe.description' | translate}}
            </nx-checkbox>

            <nx-checkbox ngDefaultControl formControlName="maz">
              <strong>{{'wewe.form.consent.maz.text' | translate}}</strong><br>
              {{ (maz.disabled ? 'wewe.form.consent.maz.info.disabled' : 'wewe.form.consent.maz.description')  | translate}}
            </nx-checkbox>

            <dsmkd-error-message-container [control]="consent" messagePrefix="wewe.form.consent.error"
                                           class="margin-bottom-16"></dsmkd-error-message-container>
            <dsmkd-error-message-container [control]="form" messagePrefix="wewe.form.error"
                                           class="margin-bottom-16"></dsmkd-error-message-container>


            <div nxRow>
              <div nxCol="12,10,10,6">
                <nx-formfield nxLabel="{{ 'wewe.form.consent.date.label' | translate }}">
                  <input nxDatefield nxInput placeholder="TT.MM.JJJJ" [nxParseFormat]="['DD.MM.YYYY','D.M.YYYY']"
                         [nxMax]="maxDate" [nxDatepicker]="picker" formControlName="date" [nxStrict]='false'/>

                  <nx-datepicker-toggle [for]="picker" nxFormfieldSuffix></nx-datepicker-toggle>
                  <nx-datepicker #picker></nx-datepicker>
                </nx-formfield>
              </div>
            </div>
            <dsmkd-error-message-container [control]="date" messagePrefix="wewe.form.consent.date.error"
                                           class="margin-bottom-16"></dsmkd-error-message-container>

          </div>

          <hr class="section-divider">

          <nx-checkbox formControlName="agentConfirmation">
            <p nxCopytext="normal" class="u-margin-bottom-none">
              {{'wewe.form.agentConfirmation.description' | translate}}
            </p>
          </nx-checkbox>
          <dsmkd-error-message-container [control]="agentConfirmation" messagePrefix="wewe.form.agentConfirmation.error"
          ></dsmkd-error-message-container>
        </ol>
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="margin-top-24">
      <div nxCol="12,12,4,4" nxAlignSelf="center,center,end" nxColOrder='first,first,last,last'>
        <div class="button-right-small-up">
          <button id="submit-button" nxButton="primary" type="submit" class="nx-margin-bottom-xs">
            {{'wewe.global.send.button' | translate}}
          </button>
        </div>
      </div>
      <div nxCol="12,12,4,4" nxAlignSelf="center" nxColOrder='last,last,first,first'
           id="back-link" class="link-text-center-small">
        <button nxPlainButton type="button" id="back-button" class="nx-margin-bottom-xs" (click)="navigateBack()">
          {{'wewe.global.back.button' | translate}}
        </button>
      </div>
    </div>

    <div nxRow nxRowJustify="center" class="nx-padding-top-s">
      <div nxCol="12,10,8,8">
        <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
      </div>
    </div>
  </div>
</form>
