import {Component, Input, OnInit} from '@angular/core';
import {AuthorizedRepresentative} from '../../../core/models/kommdata.model';

@Component({
  selector: 'dsmkd-komm-data-programm-overview',
  templateUrl: './komm-data-program-overview.component.html',
  styleUrls: ['./komm-data-program-overview.component.scss']
})
export class KommDataProgramOverviewComponent implements OnInit {

  @Input() mazActive = false;
  @Input() weweActive = false;
  @Input() deweActive = false;
  @Input() personName: string;
  @Input() legalPerson: boolean;
  @Input() authorizedRepresentative: AuthorizedRepresentative;
  @Input() showErrorMissingKommData: boolean;
  @Input() hasAddress: boolean;
  @Input() isNovaUser: boolean;

  public checkedBothPrograms: boolean;
  public checkedMaz: boolean;
  public checkedWEWE: boolean;
  public checkedPersonalIdentification = false;

  advancedSettings: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.mazActive && !this.weweActive) {
      this.checkedBothPrograms = true;
    }
    if (!this.mazActive && this.weweActive) {
      this.checkedMaz = true;
    }
  }

  toggleAdvancedSettings() {
    this.advancedSettings = !this.advancedSettings;
  }

  noProgramActive(): boolean {
    return !this.mazActive && !this.weweActive || !this.deweActive;
  }

  showIdentNatPerson(): boolean {
    return (this.checkedBothPrograms || this.checkedMaz) && !this.legalPerson && this.hasAddress;
  }

  shouldShowDewe(): boolean {
    return this.deweActive && this.isNovaUser;
  }
}
