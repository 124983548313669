import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'displayedNameProgramType'
})
export class DisplayedNameProgramTypePipe implements PipeTransform {

  transform(programParticipation: string, ...args: unknown[]): string {
    switch (programParticipation) {
    case 'WEWE':
      return 'wEWE';
    case 'ESB':
      return 'EsB';
    case 'DEWE':
      return 'DEWE';
    default:
      return programParticipation;
    }
  }
}
