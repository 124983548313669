<nxt-contact-data-input
  [validation]="true"
  [name]=fieldType
  [type]=fieldType
  [text]="fieldText"
  #field
  [(ngModel)]="model"
  (ngModelChange)="optOutStatusChange($event)"
  [options]="options"
  [optoutDisabled]="optoutDisabled"
  (input)="fieldType === 'phone' ? sanitizePhoneInput($event): ''"
>
</nxt-contact-data-input>
