import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {KommDataService} from '../services/komm-data.service';
import {UrlApiService} from '../services/url-api.service';
import {ModelService} from '../services/model.service';
import {KommDataModel} from '../models/kommdata.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KommDataResolver implements Resolve<KommDataModel> {

  constructor(private readonly kommDataService: KommDataService,
              private readonly urlApiService: UrlApiService,
              private readonly modelService: ModelService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KommDataModel> {

    const inboundRoute = this.urlApiService.getParams()?.inboundRoute;

    return this.kommDataService.getKommData(inboundRoute).pipe(
      map(value => {
        this.modelService.setKommData(value.body);
        return value.body;
      })
    );

  }
}
