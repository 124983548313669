<div>
  <h2 nxHeadline="subsection-medium" class="nx-font-weight-semibold nx-margin-bottom-m">
    Programmteilnahmen
  </h2>

  <h3 *ngIf="mazActive || weweActive || deweActive" nxHeadline="subsection-small">
    Aktive Programmteilnahmen und erteilte Einwilligungen
  </h3>

  <div nxRow *ngIf="deweActive && isNovaUser">
    <div nxCol="1">
      <nx-icon name="check-circle" size="s" class="dsmkd-check-icon"></nx-icon>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-bottom-s">
        <span class="nx-font-weight-bold">dEWE</span>
        – Dateneinwiligungserklärung
      </p>
    </div>
  </div>

  <div nxRow *ngIf="weweActive">
    <div nxCol="1">
      <nx-icon name="check-circle" size="s" class="dsmkd-check-icon"></nx-icon>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-bottom-s">
        <span class="nx-font-weight-bold">wEWE</span>
        – Werbeeinwilligungserklärung
      </p>
    </div>
  </div>

  <div nxRow *ngIf="mazActive">
    <div nxCol="1">
      <nx-icon name="check-circle" size="s" class="dsmkd-check-icon"></nx-icon>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal"><span class="nx-font-weight-bold">Meine Allianz</span></p>
    </div>
  </div>

  <div *ngIf="!weweActive || !mazActive || !deweActive">
    <h3 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold">
      Zu Programmen einladen (optional)
    </h3>
  </div>

  <div *ngIf="showErrorMissingKommData">
    <nx-message nxContext="error" class="nx-margin-top-0 nx-margin-bottom-m">
      <span class="nx-font-weight-bold">Fehlende Kontaktdaten</span><br />
      Um die Einladung zu versenden, geben Sie bitte sowohl eine E-mail-Adresse als auch eine Mobilfunknummer an.
    </nx-message>
  </div>

  <nx-checkbox
    *ngIf="noProgramActive()"
    [(checked)]="checkedBothPrograms"
    class="nx-margin-bottom-s">
    Einladung zu allen Programmen senden
  </nx-checkbox>

  <div *ngIf="shouldShowDewe()" nxRow>
    <div nxCol="1">
      <div *ngIf="checkedBothPrograms; else weweInactiveIcon">
        <nx-icon size="s" name="product-check" class="dsmkd-check-icon"></nx-icon>
      </div>
      <ng-template #weweInactiveIcon>
        <nx-icon size="s" name="product-cross" class="dsmkd-close-icon"></nx-icon>
      </ng-template>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-0">
        <span class="nx-font-weight-bold">dEWE</span> – Dateneinwilligungserklärung
      </p>
    </div>
  </div>

  <div *ngIf="shouldShowDewe()" nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="padding-left-0">
      <div *ngIf="!advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Kunden-/Interessentendaten dürfen von der Agentur verarbeitet und langfristig gespeichert werden.
        </p>
      </div>
      <div *ngIf="advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Die Werbeeinwilligungserklärung (wEWE) ermöglicht die
          vertriebliche Ansprache von Kund:innen oder Interessent:innen durch Vetreter:innen
          und die Gesellschaft. Sie ist daher ein wichtiges Element für unser vertriebliches Wachstum.
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="!weweActive" nxRow>
    <div nxCol="1">
      <div *ngIf="checkedBothPrograms; else weweInactiveIcon">
        <nx-icon size="s" name="product-check" class="dsmkd-check-icon"></nx-icon>
      </div>
      <ng-template #weweInactiveIcon>
        <nx-icon size="s" name="product-cross" class="dsmkd-close-icon"></nx-icon>
      </ng-template>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-0">
        <span class="nx-font-weight-bold">wEWE</span> – Werbeeinwilligungserklärung
      </p>
    </div>
  </div>
  <div *ngIf="!weweActive" nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="padding-left-0">
      <div *ngIf="!advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Vertriebliche Ansprache durch Agentur und die Gesellschaft wird gestattet.
        </p>
      </div>
      <div *ngIf="advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Die Werbeeinwilligungserklärung (wEWE) ermöglicht die
          vertriebliche Ansprache von Kund:innen oder Interessent:innen durch Vetreter:innen
          und die Gesellschaft. Sie ist daher ein wichtiges Element für unser vertriebliches Wachstum.
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="!mazActive" nxRow>
    <div nxCol="1">
      <div *ngIf="!weweActive">
        <div *ngIf="checkedBothPrograms && (hasAddress || legalPerson); else mazInactiveIcon">
          <nx-icon id="program-overview-maz-selectable" size="s" name="product-check"
                   class="dsmkd-check-icon"></nx-icon>
        </div>
        <ng-template #mazInactiveIcon>
          <nx-icon id="program-overview-maz-not-selectable" size="s" name="product-cross" class="dsmkd-close-icon"></nx-icon>
        </ng-template>
      </div>
      <nx-checkbox *ngIf="weweActive" [(checked)]="checkedMaz">
      </nx-checkbox>
    </div>
    <div nxCol="11" class="padding-left-0">
      <p nxCopytext="normal" class="nx-margin-0">
        <span class="nx-font-weight-bold">Meine Allianz</span>
      </p>
    </div>
  </div>
  <div *ngIf="!mazActive" nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="padding-left-0">
      <div *ngIf="!advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          In Meine Allianz (MAZ) finden Kund:innen alles rund um ihre Verträge mit der Allianz.
        </p>
      </div>
      <div *ngIf="advancedSettings">
        <p nxCopytext="normal" class="nx-margin-bottom-s">
          Im Meine Allianz (MAZ) Portal finden Kund:innen alles rund um ihre Verträge mit der Allianz.
          Sie als Vertreter:in erhalten über MAZ passgenaue Leads Ihrer Kund:innen
          und können über das MAZ Postfach direkt mit Ihren Kund:innen kommunizieren.
        </p>
      </div>
      <nx-message *ngIf="!hasAddress && !legalPerson" nxContext="warning">
        <span id="maz-hint-address-not-available" class="nx-font-weight-bold">Fehlende Postanschrift: Keine MAZ-Einladung möglich</span><br/>
        Bitte ergänzen Sie zuerst in AMIS.NOW eine Adresse, um anschließend über diesen Service zu Meine Allianz
        einzuladen.
      </nx-message>
    </div>
  </div>

</div>

<div *ngIf="showIdentNatPerson()">
  <div nxRow>
    <div nxCol="1"></div>
    <div nxCol="11" class="section-divider padding-left-0">
      <h4 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold nx-margin-top-m">
        Vereinfachte Meine Allianz Freischaltung für {{personName}}
      </h4>

      <nx-checkbox [(checked)]="checkedPersonalIdentification" class="nx-margin-bottom-s">
        Ich habe {{personName}} anhand eines Ausweisdokuments identifiziert
      </nx-checkbox>
      <p nxCopytext="normal">
        Mit dieser Identifikation durch Sie kann nach der Anmeldung sofort auf
        Meine Allianz und eigene Verträge zugegriffen werden.
        Alternativ erfolgt die Identifikation im Anmeldeprozess
        per Brief-Code oder Sofortidentifizierung.
      </p>
    </div>
  </div>
</div>

<div *ngIf="!mazActive || !weweActive" nxRow>
  <div nxCol="1">
    <nx-icon *ngIf="!advancedSettings" size="s" name="chevron-down" class="dsmkd-chevron-icon"></nx-icon>
    <nx-icon *ngIf="advancedSettings" size="s" name="chevron-up" class="dsmkd-chevron-icon"></nx-icon>
  </div>
  <div nxCol="11" class="padding-left-0">
    <button
      (click)="toggleAdvancedSettings()"
      nxPlainButton
      type="button">
      <span id="additional-information" *ngIf="!advancedSettings">Erweiterte Informationen und Einstellungen</span>
      <span *ngIf="advancedSettings">zurück zur Kurzübersicht</span>
    </button>
  </div>
</div>

<dsmkd-identified-check *ngIf="checkedMaz || (!mazActive && checkedBothPrograms)"
                        [legalPerson]="legalPerson"
                        [kundenName]="personName"
                        [(isIdent)]="checkedPersonalIdentification"
                        [(authRep)]="authorizedRepresentative"
></dsmkd-identified-check>
