import {Component, OnInit, ViewChild} from '@angular/core';
import {ContactDataInputComponent} from '../../../shared/components/contact-data-input/contact-data-input.component';
import {Router} from '@angular/router';
import {ModelService} from '../../../core/services/model.service';
import {KommDataItem} from '../../../core/models/kommdata.model';
import {AppStateService} from '../../../core/services/app-state.service';
import {
  KommDataChangeSummaryItem,
  KommDataChangeSummaryModel,
  KommDataChangeSummaryType
} from '../../model/komm-data-change-summary.model';
import {ServiceHubRedirectService} from '../../../core/services/service-hub-redirect.service';
import {ConfigurationService} from '../../../core/services/config.service';
import {KommDataService} from '../../../core/services/komm-data.service';
import {AmslPersonService} from '../../../core/services/amsl-person.service';
import {UrlApiService} from '../../../core/services/url-api.service';
import {catchError, map} from 'rxjs/operators';
import {merge} from 'rxjs';
import {MobileNumberFormatPipe} from '../../../core/pipes/mobile-number-format.pipe';

@Component({
  selector: 'dsmkd-change-maz',
  templateUrl: './komm-data-change-maz.component.html',
  styleUrls: ['./komm-data-change-maz.component.scss']
})
export class KommDataChangeMazComponent implements OnInit {

  @ViewChild('emailField') emailField: ContactDataInputComponent;
  @ViewChild('mobileNumberField') mobileNumberField: ContactDataInputComponent;

  emailFieldType = 'email';
  mobileNumberFieldType = 'phone';

  emails: KommDataItem[] = [];
  mobileNumbers: KommDataItem[] = [];

  checkedLegal: boolean = false;
  kommDataChangeSummaryModel: KommDataChangeSummaryModel;
  preselectedEmail: KommDataItem;
  preselectedMobileNumber: KommDataItem;
  isDataPopulated: boolean = false;

  constructor(private readonly modelService: ModelService,
              private readonly router: Router,
              private readonly appStateService: AppStateService,
              private readonly serviceHubRedirectService: ServiceHubRedirectService,
              private readonly configurationService: ConfigurationService,
              private readonly kommdataService: KommDataService,
              private readonly amslPersonService: AmslPersonService,
              private readonly urlApiService: UrlApiService,
              private readonly mobileNumberFormatPipe: MobileNumberFormatPipe) {
  }

  ngOnInit(): void {
    this.configurationService.getConfig().subscribe(config => {
      if (config.changeMazKommdataMaintenance) {
        this.router.navigate(['error', {id: 99999}]);
      }
    });

    this.kommdataService.hasDigitalId().subscribe(
      k => {
        if (!k.body.valueOf()) {
          this.router.navigate(['error', {id: 20014}]);
        }
      });

    this.fetchData();
  }

  private populateModelData(kommData: any): void {
    if (kommData.amslData) {
      this.modelService.setAmslKommData(kommData.amslData);
    }
    if (kommData.ciamData) {
      this.modelService.setKommData(kommData.ciamData);
    }
  }

  private populateData(): void {
    // Sequence is important
    this.populateCiamKommdata();
    this.populateAmslKommdata();
    this.clearDuplicateData();
    this.setPreselectedKommdata();
    this.setPreselectedKommdataWhenBack();
    this.clearStashedKommdata();
  }

  private fetchData(): void {
    const amslData$ = this.amslPersonService.getKommData().pipe(
      map(value => {
        return {amslData: value.body};
      }),
      catchError(() => {
        return [];
      })
    );

    const ciamData$ = this.kommdataService.getKommData(this.urlApiService.getParams()?.inboundRoute).pipe(
      map(value => {
        return {ciamData: value.body};
      }), catchError(() => {
        this.router.navigate(['error', {id: 99999}]);
        return [];
      }));
    merge(amslData$, ciamData$).subscribe(
      kommdata => {
        this.populateModelData(kommdata);
      },
      (error) => {
      },
      () => {
        this.populateData();
        //when all the observables are processed this function will be called
        this.isDataPopulated = true;
      }
    );
  }

  private populateCiamKommdata(): void {
    const model = this.modelService?.getKommData();
    if (model?.emails) {
      this.emails.push(...model.emails);
    }

    if (model?.mobileNumbers) {
      this.mobileNumbers.push(...model.mobileNumbers);
    }
  }

  private populateAmslKommdata(): void {
    const amslKommDataModel = this.modelService?.getAmslKommData();
    if (amslKommDataModel?.personEmails) {
      this.emails.push(...amslKommDataModel.personEmails);
    }

    if (amslKommDataModel?.personMobileNumbers) {
      this.mobileNumbers.push(...amslKommDataModel.personMobileNumbers);
    }
  }

  private clearDuplicateData(): void {
    this.emails = this.removeDuplicates(this.emails);
    this.mobileNumbers = this.removeDuplicates(this.mobileNumbers.map(number => {
      number.value = this.mobileNumberFormatPipe.transform(number?.value);
      return number;
    }));
  }

  private removeDuplicates(from: KommDataItem[]): KommDataItem[] {
    const tempItems: KommDataItem[] = [];
    from.forEach(item => {
      if (!tempItems.find(e => e.value === item.value)) {
        tempItems.push(item);
      }
    });
    return tempItems;
  }

  private setPreselectedKommdata(): void {
    const model = this.modelService?.getKommData();
    const mazEmail: string = model?.participations.find(p => p.type === 'MAZ')?.emails[0]?.value;
    const mazMobileNumber: string = model?.participations.find(p => p.type === 'MAZ')?.mobileNumbers[0]?.value;

    this.preselectedEmail = this.emails.find(email => email.value === mazEmail);
    this.preselectedMobileNumber = this.mobileNumbers.find(mobileNumber => mobileNumber.value === mazMobileNumber);

    if (!this.preselectedEmail) {
      this.preselectedEmail = this.emails[0];
    }else{
      this.preselectedEmail.maz = true;
    }

    if (!this.preselectedMobileNumber) {
      this.preselectedMobileNumber = this.mobileNumbers[0];
    }else{
      this.preselectedMobileNumber.maz = true;
    }
  }

  private setPreselectedKommdataWhenBack(): void {
    this.kommDataChangeSummaryModel = this.appStateService.kommDataChangeSummary;

    const preselectionEmailWhenBack = this.preselectIfBack(this.kommDataChangeSummaryModel?.kommdataChangeEmails, this.emails);
    if (preselectionEmailWhenBack) {
      this.preselectedEmail = preselectionEmailWhenBack;
    }
    const preselectionMobileNumberWhenBack = this.preselectIfBack(this.kommDataChangeSummaryModel?.kommdataChangeMobileNumbers, this.mobileNumbers);
    if (preselectionMobileNumberWhenBack) {
      this.preselectedMobileNumber = preselectionMobileNumberWhenBack;
    }
  }

  private clearStashedKommdata(): void {
    this.kommDataChangeSummaryModel.kommdataChangeEmails = [];
    this.kommDataChangeSummaryModel.kommdataChangeMobileNumbers = [];
  }


  onSubmit(): void {
    // Validation applied only if fields invalid && touched
    this.emailField?.markAsTouched();
    this.mobileNumberField?.markAsTouched();

    if (this.areAllEnabledFieldsValid([this.emailField, this.mobileNumberField])) {

      const oldEmail = this.emails.filter(value => value.maz)[0];
      const oldMobileNumber = this.mobileNumbers.filter(value => value.maz)[0];

      if (oldEmail) {
        this.kommDataChangeSummaryModel.kommdataChangeEmails.push({
          type: KommDataChangeSummaryType.OLD,
          item: oldEmail
        });
      }
      if (oldMobileNumber) {
        this.kommDataChangeSummaryModel.kommdataChangeMobileNumbers.push({
          type: KommDataChangeSummaryType.OLD,
          item: oldMobileNumber
        });
      }

      const newEmail = this.emailField?.getKommDataItem();
      if (newEmail && newEmail.value !== oldEmail?.value) {
        this.kommDataChangeSummaryModel.kommdataChangeEmails.push({
          type: KommDataChangeSummaryType.NEW,
          item: newEmail
        });
      }

      const newMobileNumber = this.mobileNumberField?.getKommDataItem();
      if (newMobileNumber && newMobileNumber?.value !== oldMobileNumber?.value) {
        this.kommDataChangeSummaryModel.kommdataChangeMobileNumbers.push({
          type: KommDataChangeSummaryType.NEW,
          item: newMobileNumber
        });
      }
      this.router.navigate(['change', 'summary']);
    }
  }

  private preselectIfBack(kommDataChangeItems: KommDataChangeSummaryItem[], allItems: KommDataItem[]): KommDataItem {
    const kommDataChangeSummaryItem = kommDataChangeItems.find(emailItem => emailItem.type === KommDataChangeSummaryType.NEW);

    if (kommDataChangeItems.length !== 0 && kommDataChangeSummaryItem) {
      const preselectedItem = allItems
        .find(item => {
          return item.value === kommDataChangeSummaryItem?.item?.value;
        });
      if (!preselectedItem) {
        return kommDataChangeSummaryItem.item;
      }
      return preselectedItem;
    }
    return null;
  }

  navigateToPreviousPage(): void {
    this.serviceHubRedirectService.doRedirect();
  }

  private areAllEnabledFieldsValid(fields: ContactDataInputComponent[]): boolean {
    return fields.filter(field => this.isFieldEnabled(field)).every(field => !this.isFieldInvalid(field));
  }

  private isFieldEnabled(field: ContactDataInputComponent): boolean {
    return field && !field.getNotSpecified();
  }

  private isFieldInvalid(field: ContactDataInputComponent): boolean {
    return field && !field.valid();
  }

  submitDisbled(): boolean {
    return !this.checkedLegal;
  }

}
