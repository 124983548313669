import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StylesModule} from '../styles/styles.module';
import {ErrorMessageContainerComponent} from './components/error-message-container/error-message-container.component';
import {CircleCheckAnimationComponent} from './components/circle-check-animation/circle-check-animation.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {HeaderUserDetailsComponent} from './components/header-user-details/header-user-details.component';
import {DefaultLayoutComponent} from './components/default-layout/default-layout.component';
import {RouterModule} from '@angular/router';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {ErrorOverlayComponent} from './components/error-overlay/error-overlay.component';
import {LoadingOverlayComponent} from './components/loading-overlay/loading-overlay.component';
import {ContactDataInputComponent} from './components/contact-data-input/contact-data-input.component';
import {NxHeaderModule} from '@aposin/ng-aquila/header';
import {NxImageModule} from '@aposin/ng-aquila/image';
import {TextareaComponent} from './components/textarea/textarea.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TextFieldModule} from '@angular/cdk/text-field';
import {VocTeaserComponent} from './components/voc-teaser/voc-teaser.component';
import {NxtVocTeaserModule} from '@nxt/ngx-ndbx-extensions/voc-teaser';
import {MobileNumberFormatPipe} from '../core/pipes/mobile-number-format.pipe';
import {LongVersionProgramTypePipe} from '../core/pipes/long-version-program-type.pipe';
import {DisplayedNameProgramTypePipe} from '../core/pipes/displayed-name-program-type.pipe';
import {CombinedProgramTypePipe} from '../core/pipes/combined-program-type.pipe';
import {HeadersComponent} from './components/headers/headers.component';
import {NxFooterModule} from '@aposin/ng-aquila/footer';

const sharedComponents = [
  ErrorMessageContainerComponent,
  CircleCheckAnimationComponent,
  FooterComponent,
  HeaderComponent,
  HeaderUserDetailsComponent,
  DefaultLayoutComponent,
  UnauthorizedComponent,
  ErrorOverlayComponent,
  LoadingOverlayComponent,
  ContactDataInputComponent,
  TextareaComponent,
  VocTeaserComponent,
  HeadersComponent,
  MobileNumberFormatPipe,
  LongVersionProgramTypePipe,
  DisplayedNameProgramTypePipe,
  CombinedProgramTypePipe
];

@NgModule({
  imports: [
    CommonModule,
    StylesModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    TextFieldModule,
    NxHeaderModule,
    NxFooterModule,
    NxImageModule,
    NxtVocTeaserModule,
  ],
  declarations: [sharedComponents],
  exports: [sharedComponents],
  providers: [
    MobileNumberFormatPipe,
    LongVersionProgramTypePipe,
    DisplayedNameProgramTypePipe,
    CombinedProgramTypePipe]
})
export class SharedModule {
}
