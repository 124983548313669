<div nxLayout="grid maxwidth"
     class="contact-data-input-remove-not-available contact-data-input-remove-info nx-margin-top-4m">

  <div nxRow nxRowJustify="center">
    <div nxCol="12,8,8,6">
      <dsmkd-headers title="Digitale Kommunikationsdaten"
                     mainTitle="Daten verwalten"
      ></dsmkd-headers>
    </div>
  </div>

  <div nxRow nxRowJustify="center">
    <div nxCol="12,12,6">
      <h3 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold">
        Meine Allianz Kommunikationsdaten
      </h3>
    </div>
  </div>

  <div *ngIf="mazEmail">
    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">
        <p nxCopytext="normal" class="nx-margin-bottom-0">
          Meine Allianz-Kunden benötigen jederzeit eine aktuelle E-Mail-Adresse und Mobilfunknummer als
          Kommunikationsdaten.
        </p>
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m">
      <div nxCol="12,12,6" class="scroll-container">
        <table nxTable condensed class="nx-table--scrollable">
          <thead>
          <tr nxTableRow>
            <th nxHeaderCell>Typ</th>
            <th nxHeaderCell>Kommunikationsdaten</th>
          </tr>
          </thead>
          <tbody>
          <tr nxTableRow>
            <td nxTableCell>E-Mail</td>
            <td nxTableCell>{{ mazEmail }}</td>
          </tr>
          <tr nxTableRow>
            <td nxTableCell>Mobil</td>
            <td nxTableCell>{{ mazMobileNumber }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div nxRow nxRowJustify="center">
      <div nxCol="12,12,6">
        <button nxButton="primary small-medium" type="button" class="nx-margin-top-2m"
                (click)="navigateToMazChange()">
          <nx-icon name="pen" class="nx-margin-right-xs"></nx-icon>
          MAZ Kommunikationsdaten ändern
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!mazEmail" nxRow nxRowJustify="center">
    <div nxCol="12,12,6" class="scroll-container">
      <nx-card class="invite-to-maz-card  nx-margin-bottom-0">
        <nx-card-header>
          <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-s">Einladung zu Meine Allianz</h4>
        </nx-card-header>
        <p nxCopytext="medium" class="nx-margin-bottom-s">
          {{customerName}} nutzt Meine Allianz noch nicht. Mit bestehenden oder neuen Kontaktdaten können die Vorteile des Kundenportals und der digitalen Kommunikation genutzt werden.
        </p>
        <button nxButton="primary small-medium" type="button" (click)="navigateToAddKommData()"
                class="nx-margin-bottom-0">
          Zu Meine Allianz einladen
        </button>
      </nx-card>
    </div>
  </div>

  <div nxRow nxRowJustify="center">
    <div nxCol="12,12,6">
      <h3 nxHeadline="subsection-xsmall" class="nx-font-weight-semibold nx-margin-top-m nx-margin-bottom-s">
        Weitere Kommunikationsdaten
      </h3>
    </div>
  </div>
  <div *ngIf="hasOtherCommunicationData()" nxRow nxRowJustify="center" class="nx-margin-top-s">
    <div nxCol="12,12,6">
      <table nxTable condensed class="nx-table--scrollable">
        <thead>
        <tr nxTableRow>
          <th nxHeaderCell>Typ</th>
          <th nxHeaderCell>Kommunikationsdaten</th>
          <th nxHeaderCell></th>
        </tr>
        </thead>
        <tbody>
        <tr nxTableRow *ngFor="let email of emails">
          <td nxTableCell>E-Mail</td>
          <td nxTableCell>{{ email.value }}
          </td>
          <td nxTableCell>
            <button nxIconButton="tertiary small" aria-label="delete" type="button"
                    class="nx-margin-bottom-0" [disabled]="isDeleteDisabled(email)"
                    (click)="deleteKommItem(email, 'email')"
                    [nxTooltip]="getTooltipText(email.deletableType, 'E-mail')"
                    nxTooltipPosition="top">
              <nx-icon name="trash" size="s" class="{{getTrashIcon(email)}}"></nx-icon>
            </button>
          </td>
        </tr>
        <tr nxTableRow *ngFor="let mobile of mobileNumbers">
          <td nxTableCell>Mobil</td>
          <td nxTableCell>{{ mobile.value | mobileNumberFormat }}</td>
          <td nxTableCell>
            <button nxIconButton="tertiary small" aria-label="delete" type="button"
                    class="nx-margin-bottom-0" [disabled]="isDeleteDisabled(mobile)"
                    (click)="deleteKommItem(mobile, 'mobile')"
                    [nxTooltip]="getTooltipText(mobile.deletableType, 'Mobilfunknummer')"
                    nxTooltipPosition="top">
              <nx-icon name="trash" size="s" class="{{getTrashIcon(mobile)}}"></nx-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-xs nx-margin-top-m">
    <div nxCol="12,12,6">
      <button nxButton="tertiary small-medium" type="button" class="padding-left-0 nx-margin-bottom-0"
              (click)="navigateToAddKommData()">
        <nx-icon name="plus" size="s" class="icon-plus nx-margin-right-2xs"></nx-icon>
        Daten hinzufügen
      </button>
    </div>
  </div>
  <div nxRow nxRowJustify="center" class="nx-padding-top-m">
    <div nxCol="12,12,6">
      <dsmkd-feedback-for-service></dsmkd-feedback-for-service>
    </div>
  </div>
</div>

<ng-template #deleteModalBody>
  <div class="delete-modal">
    <div>
      <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-m">
        Endgültig löschen?
      </h3>
      <span nxCopytext class="nx-margin-bottom-0">Möchten Sie die </span>
      <span nxCopytext *ngIf="kommDataToDeleteType === 'email'">E-Mail-Adresse<b> {{kommDataToDelete.value}}</b></span>
      <span nxCopytext *ngIf="kommDataToDeleteType === 'mobile'">Mobilfunknummer<b> {{kommDataToDelete.value | mobileNumberFormat}}</b></span>
      <span nxCopytext>
          wirklich löschen? Die Daten werden sofort
          und unwiderruflich entfernt.</span>
    </div>
    <div nxRow>
      <div nxCol="12" style="text-align: right;" class="nx-margin-top-2m link-text-center-small">
        <button nxButton="secondary small-medium" (click)="closeDeleteDialog()"
                class="nx-margin-bottom-2xs link-text-center-small nx-margin-right-s">
          ABBRECHEN
        </button>
        <button nxButton="primary small-medium" class="nx-margin-bottom-2xs" (click)="sendDeleteRequest()">
          Löschen
        </button>
      </div>
    </div>
  </div>
</ng-template>
