import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longVersionProgramType'
})
export class LongVersionProgramTypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
    case 'WEWE':
      return 'Werbliche Einwilligungserklärung';
    case 'ESB':
      return 'E-Mail statt Brief';
    case 'AVP':
      return  'Allianz Vorteilsprogramm';
    default:
      return '';
    }
  }

}
